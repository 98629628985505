import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [2];

export const dictionary = {
		"/(authed)": [3,[2]],
		"/auth_error": [15],
		"/(authed)/case": [~4,[2]],
		"/(authed)/comparison": [~5,[2]],
		"/(authed)/dedupe-documents": [~7,[2]],
		"/(authed)/dedup": [~6,[2]],
		"/(authed)/extract": [~8,[2]],
		"/(authed)/merge": [~9,[2]],
		"/(authed)/ptd": [~10,[2]],
		"/(authed)/review": [~11,[2]],
		"/(authed)/segmentation": [~12,[2]],
		"/signin": [16],
		"/signout": [17],
		"/(authed)/summary": [~13,[2]],
		"/[...catchall]": [14]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';